import * as z from 'zod';

export let yellowBtnClass =
    'btn-leo btn-leo-primary btn-lg font-black text-24 py-6 whitespace-normal';

export let bigWhiteBtnClass =
    'btn-leo btn-white btn-lg font-black text-24 py-10 whitespace-normal';

export const zButtonCtaProps = z.object({
    class: z.string().nullish(),
    href: z.string().nullish(),
    text: z.string().nullish(),
    icon: z.string().nullish(),
    subject: z.string().nullish(),
    to: z.string().nullish(),
    withCompanyNameRequired: z.boolean().optional(),
    withPhoneRequired: z.boolean().optional(),
    modalTitle: z.string().nullish(),
    messagePlaceholder: z.string().nullish(),
    relatedCategoryId: z.number().nullish(),
});

export type ButtonCtaProps = z.infer<typeof zButtonCtaProps>;
