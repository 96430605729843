import * as z from 'zod';
import { svelteInitializeElements } from '$lib/legacy/svelteInitializeElements';
import { zParticipantInput } from '$lib/utils/participantUtils';
import { zSearchHeaderFormData } from '$components/SearchHeader/SearchHeaderFormData';
import { zButtonCtaProps } from '$components/ServicePage/utils';

window.addEventListener('DOMContentLoaded', (_event) => {
    svelteInitializeElements({
        elements: document.querySelectorAll<HTMLElement>('[data-svelte="Toasts"]'),
        getComponentClass: () =>
            import('$components/Toast/Toasts.svelte').then((m) => m.default),
        getProps: (element) =>
            z
                .object({})
                .passthrough()
                .parse(JSON.parse(element.getAttribute('data-props') ?? '{}')),
    }).catch(console.error);

    svelteInitializeElements({
        elements: document.querySelectorAll<HTMLElement>('[data-svelte="FollowButton"]'),
        getComponentClass: () =>
            import('$components/FollowButton/FollowButtonWithLoader.svelte').then(
                (m) => m.default,
            ),
        getProps: (element) =>
            z
                .object({
                    target: z.object({
                        companyId: z.number().nullish(),
                        userId: z.number().nullish(),
                    }),
                    class: z.string().optional(),
                    followedClassName: z.string().optional(),
                    unfollowedClassName: z.string().optional(),
                })
                .passthrough()
                .parse(JSON.parse(element.getAttribute('data-props') ?? '{}')),
    }).catch(console.error);

    svelteInitializeElements({
        elements: document.querySelectorAll<HTMLElement>('[data-svelte="Navbar"]'),
        getComponentClass: () =>
            import('$components/Navbar/NavbarWithLoader.svelte').then((m) => m.default),
        getProps: (element) =>
            z
                .object({
                    cmsCountryUrl: z.string(),
                    navbarCountryLink: z
                        .object({
                            withCountry: z.string(),
                            withoutCountry: z.string(),
                        })
                        .optional(),
                })
                .passthrough()
                .parse(JSON.parse(element.getAttribute('data-props') ?? '{}')),
    }).catch(console.error);

    svelteInitializeElements({
        elements: document.querySelectorAll<HTMLElement>('[data-svelte="InboxBar"]'),
        getComponentClass: () =>
            import('$components/Inbox/InboxBar/InboxBarLegacy.svelte').then(
                (m) => m.default,
            ),
        getProps: (_element) => ({}),
    }).catch(console.error);

    svelteInitializeElements({
        elements: document.querySelectorAll<HTMLElement>(
            '[data-svelte="InboxCreateThreadButton"]',
        ),
        getComponentClass: () =>
            import('$components/Inbox/InboxCreateThreadButton.svelte').then(
                (m) => m.default,
            ),
        getProps: (element) =>
            z
                .object({
                    participants: z.array(zParticipantInput),
                    label: z.string(),
                    icon: z.string().nullish(),
                    class: z.string().nullish(),
                })
                .passthrough()
                .parse(JSON.parse(element.getAttribute('data-props') ?? '{}')),
    }).catch(console.error);

    svelteInitializeElements({
        elements: document.querySelectorAll<HTMLElement>('[data-svelte="SearchHeader"]'),
        getComponentClass: () =>
            import('$components/SearchHeader/SearchHeaderInLayoutLegacy.svelte').then(
                (m) => m.default,
            ),
        getProps: (element) =>
            z
                .object({
                    initialValues: zSearchHeaderFormData.partial(),
                })
                .passthrough()
                .parse({
                    ...JSON.parse(element.getAttribute('data-props') ?? '{}'),
                }),
    }).catch(console.error);

    svelteInitializeElements({
        elements: document.querySelectorAll<HTMLElement>('[data-svelte="FeedsSection"]'),
        getComponentClass: () =>
            import('$components/Feed/FeedsSection.svelte').then((m) => m.default),
        getProps: (element) =>
            z
                .object({
                    author: z.object({
                        companyId: z.number().nullish(),
                        userId: z.number().nullish(),
                    }),
                    maxPerPage: z.number().optional(),
                    title: z.string().optional(),
                    fullPageUrl: z.string().optional(),
                    usePagination: z.boolean().optional(),
                    withButton: z.boolean().optional(),
                    buttonLabel: z.string().optional(),
                })
                .passthrough()
                .parse(JSON.parse(element.getAttribute('data-props') ?? '{}')),
    }).catch(console.error);

    svelteInitializeElements({
        elements: document.querySelectorAll<HTMLElement>('[data-svelte="FeedPostForm"]'),
        getComponentClass: () =>
            import('$components/Feed/FeedPostForm.svelte').then((m) => m.default),
        getProps: (element) =>
            z
                .object({
                    initialAuthor: z.object({
                        companyId: z.number().nullish(),
                        userId: z.number().nullish(),
                    }),
                    formName: z.string().optional(),
                    initialBody: z.string().optional(),
                    sharedPostId: z.number().optional(),
                    usePagination: z.boolean().optional(),
                    autoFocus: z.boolean().optional(),
                    context: z.string().optional(),
                })
                .passthrough()
                .parse(JSON.parse(element.getAttribute('data-props') ?? '{}')),
    }).catch(console.error);

    svelteInitializeElements({
        elements: document.querySelectorAll<HTMLElement>('[data-svelte="BannerCookies"]'),
        getComponentClass: () =>
            import('$components/BannerCookies/BannerCookies.svelte').then(
                (m) => m.default,
            ),
        getProps: (_element) => ({}),
    }).catch(console.error);

    svelteInitializeElements({
        elements: document.querySelectorAll<HTMLElement>(
            '[data-svelte="PanelMultiQuoteForm"]',
        ),
        getComponentClass: () =>
            import('$components/MultiQuoteForm/PanelMultiQuoteForm.svelte').then(
                (m) => m.default,
            ),
        getProps: (element) =>
            z
                .object({
                    categoryId: z.number().nullish(),
                    country: z.string().nullish(),
                    class: z.string().nullish(),
                    relatedCmsPostId: z.number().nullish(),
                })
                .passthrough()
                .parse(JSON.parse(element.getAttribute('data-props') ?? '{}')),
    }).catch(console.error);

    svelteInitializeElements({
        elements: document.querySelectorAll<HTMLElement>(
            '[data-svelte="ButtonMultiQuoteForm"]',
        ),
        getComponentClass: () =>
            import('$components/MultiQuoteForm/ButtonMultiQuoteForm.svelte').then(
                (m) => m.default,
            ),
        getProps: (element) =>
            z
                .object({
                    categoryId: z.number().nullish(),
                    country: z.string().nullish(),
                    class: z.string().nullish(),
                    defaultClass: z.string().nullish(),
                    relatedCmsPostId: z.number().nullish(),
                    html: z.string().nullish(),
                })
                .passthrough()
                .parse(JSON.parse(element.getAttribute('data-props') ?? '{}')),
    }).catch(console.error);

    svelteInitializeElements({
        elements: document.querySelectorAll<HTMLElement>(
            '[data-svelte="ServicePageButtonCta"]',
        ),
        getComponentClass: () =>
            import('$components/ServicePage/ButtonCta.svelte').then((m) => m.default),
        getProps: (element) => ({
            cta: zButtonCtaProps
                .passthrough()
                .parse(JSON.parse(element.getAttribute('data-props') ?? '{}')),
        }),
    }).catch(console.error);

    svelteInitializeElements({
        elements: document.querySelectorAll<HTMLElement>('[data-svelte="CompanyMenu"]'),
        getComponentClass: () =>
            import('$components/CompanyMenu/CompanyMenu.svelte').then((m) => m.default),
        getProps: (element) =>
            z
                .object({
                    companyId: z.number(),
                    companyUrl: z.string(),
                })
                .passthrough()
                .parse(JSON.parse(element.getAttribute('data-props') ?? '{}')),
    }).catch(console.error);
});
